<template>
  <div class="folder-document">
    <div class="folder-header">
      <h1>{{ $t("document.title") }}</h1>
    </div>
    <div class="content-document">
      <v-row class="d-flex">
        <v-col cols="6" md="6" lg="6">
          <div class="folder" @click="documentIn">
            {{ $t("document.document in") }}
            <div class="arrow-in">
              <i class="fas fa-exchange-alt"></i>
            </div>
          </div>
        </v-col>
        <v-col cols="6" md="6" lg="6">
          <div class="folder" @click="documentOut">
            {{ $t("document.document out") }}
            <div class="arrow-out">
              <i class="fas fa-exchange-alt"></i>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    documentIn() {
      this.$router.push({
        name: "document.table",
        query: {
          type: "in"
        }
      })
    },
    documentOut() {
      this.$router.push({
        name: "document.table",
        query: {
          type: "out"
        }
      })
    },

  }
}
</script>
<style scoped lang="scss">
.folder-document {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;

  .folder-header {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 60px;
    position: relative;

    .btn-document {
      width: 300px;
      height: 100px;
      position: absolute;
      right: 0;
    }

    h1 {
      font-family: $font-family;
    }
  }

  .content-document {
    width: 900px;
    height: auto;
    margin-left: 150px;

    .folder {
      width: 300px;
      height: 210px;
      margin: 50px auto 0;
      position: relative;
      background-color: rgba(255, 218, 121, 0.9);
      border-radius: 0 12px 12px 12px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      color: #FFFFFF;
      font-family: $font-family;

      .arrow-in {
        position: absolute;
        bottom: 25px;
        font-size: 2.4rem;

        i {
          color: #6CB669;
        }
      }

      .arrow-out {
        position: absolute;
        bottom: 25px;
        font-size: 2.4rem;

        i {
          color: #ff0000;
        }
      }
    }

    .folder:before {
      content: '';
      width: 50%;
      height: 30px;
      border-radius: 60px 60px 10px 10px;
      background-color: rgba(255, 218, 121, 0.9);
      position: absolute;
      top: -25px;
      left: 0;
    }
  }


}
</style>
